<!--
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-12-02 10:52:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-02 16:30:55
-->
<template>
    <div class="content">
        <div class="" style="display: flex;justify-content: center;align-items: center;width:27rem">
            <div class="posi-el">{{a}} / {{total}}</div>
            <div class="grayLine">
                
                <div class="redLine" :style="{width:decimalToPercent()}">
                    
                </div>
            </div>
            <!-- <div class="" style="margin-right: 10px;">
                {{decimalToPercent()}}
            </div> -->
        </div>
        
        <!-- <div class="bot">
            <div class="botTil">
                已到人数：{{a}}
            </div>
            <div class="botTil">
                总人数：{{total}}
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: {
            a: {
                type: Number || String
            },
            total: {
                type: Number || String
            }
        },
        data() {
            return {
                // a: 10,
                // total: 30,
            }
        },
        methods: {
            //小数转化百分比
            decimalToPercent() {
                let data = ((this.a/this.total)*100).toFixed(2)+'%'
                return data
            },
        }
    }
</script>

<style scoped lang="scss">
.posi-el{
    position: absolute;
    // left: 172px;
    font-weight: bold;
    // color: #444;
    color: #f9fbfb;
    font-size: 18px;
}
    .content {
        position: relative;
        .bot {
            display: flex;
            justify-content: space-between;
            // margin: 0 30px;
            .botTil {
                height: 20px;
                font-size: 15px;
                font-family: Arial;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
            }
        }
        .grayLine {
            // margin: 30px;
            height: 18px;
            width: 100%;
            // background: #EAECEE;
            background: #E0E2E6;
            border-radius: 5px;
            .redLine {
                // background: #FB4243;
                height: 100%;
                // background-image: -webkit-linear-gradient(230deg, #91FEBD, #194E72);
                background: #00BEA4;
                -webkit-text-fill-color: transparent;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
                transition: all 1s ease-in-out;
            }
        }
    }
    
</style>