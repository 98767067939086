<template>
  <div class="panel__item clearfix">
    <div class="left panel__label">
      {{ label }}
    </div>
    <div
      class="right panel__input"
    >
      <el-input
        v-model="value"
        size="small"
        placeholder="请填写"
        @change="change"
        style="float: left"
      />
      <el-button
        v-if="isLastItem"
        type="primary"
        size="small"
        @click="deleteItem"
        style="float: right"
      >一</el-button>
      <div
        class="add-item"
        @click="addItem"
      >
        <el-button
          type="primary"
          size="small"
        >十</el-button><span class="add-item__desc"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    defaultValue: {
      type: Object,
      default: () => {
        return {
          label: '',
          value: '',
          isLastItem: false
        }
      }
    }
  },
  data () {
    return {
      value: '',
      label: '',
      isLastItem: false
    }
  },
  created () {
    this.value = this.defaultValue.value
    this.label = this.defaultValue.label
    // this.isLastItem = this.defaultValue.isLastItem
  },
  methods: {
    change (value) {
      this.$emit('change', value)
    },
    deleteItem () {
      this.$emit('deleteItem', this.label, this.value)
    },
    addItem () {
      this.$emit('addItem')
    }
  }
}
</script>
<style scoped>
.clearfix::after{
    content: "";
    clear: both;
}
</style>